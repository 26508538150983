import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * 處理 Ajax Request 的攔截器
 */
export class AuthHttpInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 攔截要發出去的 HTTP Request
    const newRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token') ?? ''}`,
      },
    });

    return next.handle(newRequest);
  }
}
