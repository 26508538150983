import { Component } from '@angular/core';
import { NavigationService } from './shared/services/navigation.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * 用於記錄上一頁的 index
   */
  private previousIndex = 0;

  /**
   * 記錄先前的頁面歷史長度
   */
  private previousLength = 0;

  constructor(private navigationService: NavigationService, private router: Router) {
    // 模擬網頁返回上一頁的網址歷程紀錄
    // 透過 router.events 來監聽路由變化，並且將歷程紀錄下來，方便後續 top bar 共同套件使用【返回】功能的時候可以正確返回
    // 限制：如果畫面重新整理，則會重新計算歷程紀錄就無法返回上一頁
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        switch (true) {
          // 第一頁 還沒有記錄長度則代表是剛進入頁面
          case !this.previousLength:
            this.previousIndex = window.history.length;
            this.navigationService.currentIndex = this.previousIndex;
            break;

          // 上一頁 如果原先記錄的 index 抓不到資料了，代表是上一頁，則將 index 設為目前的 index
          case !this.navigationService.pathHistories[this.previousIndex]:
            this.previousIndex = this.navigationService.currentIndex;
            break;

          // 下一頁 如果原先的歷程長度小於目前的歷程長度，則代表是下一頁，則將 index 設為目前的 index
          case this.previousLength < window.history.length:
            this.previousIndex = window.history.length;
            this.navigationService.currentIndex = this.previousIndex;
            break;
        }

        // 更新歷程長度
        this.previousLength = window.history.length;

        // 更新 ｜ 新增歷程紀錄
        this.navigationService.pathHistories[this.previousIndex] = event.url;
        this.navigationService.historyLength = Object.keys(this.navigationService.pathHistories).length;
      }
    });
  }
}
