import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MyAccountViewModel } from '@aiii/nn-types/view-models/account';
import { Account, Role } from '@aiii/nn-types/product/account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  /**
   * 取得帳戶持有者本人可查詢到的帳戶資料
   */
  public async getMyAccount(): Promise<MyAccountViewModel> {
    return await lastValueFrom(this.http.get<MyAccountViewModel>(environment.api.account.getMyAccount));
  }

  /**
   * 根據 role 和 role id 找出對應 account 資料
   * @param role 角色名稱
   * @param roleId 角色 id
   * @returns string
   */
  public async getLineUserIdByRoleAndRoleId(role: Role.Physician, roleId: number): Promise<string> {
    return await lastValueFrom(
      this.http.get<string>(
        environment.api.account.getLineUserId.replace(':role', role).replace(':roleId', roleId.toString())
      )
    );
  }

  /**
   * 連結帳戶的 LINE User ID 跟手機號碼
   * @description 因為只透過 OA 註冊帳戶的人其資料只會有 LINE User ID，而只透過 APP 手機註冊帳戶的人其資料只會有手機號碼。當用 LINE 登入的人要註冊角色時需要填寫手機號碼，此時要連結這兩筆帳戶資料
   */
  public async linkAccount(phoneToken: string): Promise<void> {
    const token: string = await lastValueFrom(
      this.http.patch(environment.api.account.linkAccount, { phoneToken }, { responseType: 'text' })
    );

    // 更新儲存在瀏覽器上的 Token
    localStorage.setItem('token', token);
  }

  /**
   * 同意使用者條款
   */
  public async setAgreeTerms(): Promise<void> {
    await lastValueFrom(this.http.post<null>(environment.api.account.setAgreeTerms, {}));
  }
}
