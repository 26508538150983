import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { liff, Liff } from '@line/liff';
import { LiffConfig } from '../shared/interfaces/liff-config';
import { NoopError } from '../shared/types/noop-error';
import { LoginService } from '../shared/services/login.service';

interface LineUserProfile {
  userId: string;
  displayName: string;
  pictureUrl?: string;
  statusMessage?: string;
  timestamp?: Date;
  email?: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LiffService {
  public liff: Liff = liff;
  public profile: LineUserProfile = {
    userId: '',
    displayName: '',
    pictureUrl: '',
    statusMessage: '',
  };

  constructor(public http: HttpClient, private firestore: AngularFirestore, private loginService: LoginService) {}

  /**
   * 執行 LIFF 登入
   * @param liffId LIFF ID
   */
  public async initLiff(liffId: string): Promise<void> {
    await liff.init({ liffId });

    this.profile = await this.liff.getProfile();
    const decodedIDToken = liff.getDecodedIDToken();
    if (decodedIDToken) {
      this.profile.email = decodedIDToken.email || '';
      this.profile.phone = (decodedIDToken as any).phone_number || ''; //TODO: 套件裡面的型別沒有 phone_number，需再確認何時會存在
    }

    await this.loginService.loginLiff({ idToken: liff.getIDToken()! });
  }

  /**
   * 檢查用戶是否有加入 OA，沒有的話要導到加入頁面
   */
  public async checkFollow(): Promise<void> {
    if (await this.isFollow()) {
      return;
    }

    location.href = environment.liff.officialAccountUrl;
    throw new NoopError();
  }

  /**
   * 判斷是否為本機開發模式
   */
  public isDevMode() {
    return location.host.includes('localhost:');
  }

  /**
   * 登入 LIFF 帳號
   * @param redirectUri 登入後跳轉的網址
   */
  public login(redirectUri = location.href) {
    localStorage.setItem('dev-liff-login', '1');
    if (!this.isDevMode()) {
      this.liff.login({
        redirectUri,
      });
    }
  }

  /**
   * 登出 LIFF 帳號
   */
  public logout() {
    localStorage.setItem('dev-liff-login', '');
    this.liff.logout();
  }

  /**
   * 判斷登入狀態
   */
  public isLoggedIn() {
    try {
      return (this.isDevMode() && !!localStorage.getItem('dev-liff-login')) || this.liff.isLoggedIn();
    } catch (err) {
      console.log('isLogin', err);
      return false;
    }
  }

  /**
   * 判斷環境 "ios" | "android" | "web"
   */
  public getOS() {
    try {
      return this.liff.getOS();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷LIFF的版本
   */
  public getVersion() {
    try {
      return this.liff.getVersion();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷LIFF的版本
   */
  public getLineVersion() {
    try {
      return this.liff.getLineVersion();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷是否為原生LIFF的環境
   */
  public isInClient() {
    try {
      return this.liff.isInClient();
    } catch (err) {
      return false;
    }
  }

  /**
   * 取得 AccessToken
   */
  public getAccessToken() {
    try {
      return this.liff.getAccessToken();
    } catch (err) {
      return '';
    }
  }

  /**
   * 取得裝置語系
   */
  public getLanguage() {
    try {
      return this.liff.getLanguage();
    } catch (err) {
      return '';
    }
  }

  /**
   * 發送並紀錄 User Log
   * @param site 必填 站台名稱
   * @param userId 必填 使用者的 userId
   * @param action 必填 事件名稱;
   * @param label 選填 自訂標籤/Key值;
   * @param value 選填 key/標籤
   * @param payload 選填 key/標籤
   */
  async log(
    site: string,
    userId: string,
    action: string,
    label?: string | number,
    value?: string | number,
    payload?: any,
    category?: 'LineLiff' | 'WebSite' | 'LineApp'
  ) {
    try {
      await this.http
        .post(`${environment.cloudrun.api}/log`, {
          site,
          userId,
          category: category || 'LineLiff',
          action,
          label,
          value,
          payload,
        })
        .toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  async getClientIP(): Promise<string> {
    try {
      const result = (await this.http.get(`${environment.cloudrun.api}/get-client-ip`).toPromise()) as any;
      return result.IP || '';
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  async getServerTimestamp(timezone = 'Asia/Taipei'): Promise<Date> {
    const result = (await this.http.get(`${environment.cloudrun.api}/get-server-timestamp`).toPromise()) as any;
    return moment.tz(result.timestamp || '', timezone).toDate();
  }

  /**
   * 查詢站台是否存在
   * @param site 站台名稱
   */
  async querySiteExists(site: string): Promise<boolean> {
    const doc = await this.firestore.doc(`/sites/${site}`).ref.get();
    return doc.exists;
  }

  /**
   * 查詢該 site 站台的 botId 、Liff ID 及 公開資料
   * @param site 站台名稱
   */
  async querySiteProfile(site: string): Promise<{
    site: string;
    botId: string;
    displayName: string;
    liff: LiffConfig;
    accountId: string;
    provider: string;
  }> {
    const result: any = await this.http.post(`${environment.cloudrun.api}/querySiteConfig`, { site }).toPromise();
    return result;
  }

  async addLabels(site: string, userId: string, labels: string[], eventAction: string, eventLabel: string) {
    if (labels.length > 0) {
      const userLabels: any = {};
      for (const label of labels) {
        userLabels[`labels.${label}.from`] = firebase.firestore.FieldValue.arrayUnion(site);
        userLabels[`labels.${label}.updateAt`] = new Date();
        userLabels[`labels.${label}.enable`] = true;
        userLabels[`labels.${label}.types`] = firebase.firestore.FieldValue.arrayUnion('liff');
      }
      await this.firestore.doc(`/lineUsers/${userId}`).update(userLabels);
    }
    this.log(site, userId, eventAction, eventLabel, labels.join(','));
  }

  /**
   * 若為手機則強制使用原生 LIFF 分享
   * @param messagea
   */
  async forcedLiffShare(site: string, message: any[]) {
    try {
      if (this.getOS() === 'android' || this.getOS() === 'ios') {
        // 若使用手機
        if (this.isInClient()) {
          // 已是原生 LIFF 環境
          await liff.shareTargetPicker(message);
        } else {
          // 手機的 LINE 瀏覽器 或 外部瀏覽器
          const siteProfile = await this.querySiteProfile(site);
          const encodeText = encodeURIComponent(JSON.stringify(message));
          const httpReferer = encodeURIComponent(location.href);
          const shareLiffUrl = `https://liff.line.me/${siteProfile.liff.shareTargetPickerLiffID}?site=${site}&messageEncode=${encodeText}`;
          location.href = shareLiffUrl;
        }
      } else {
        // 桌機 直接使用 SDK 分享
        if (this.isLoggedIn()) {
          await liff.shareTargetPicker(message);
        } else {
          this.login();
        }
      }
    } catch (error) {
      alert((error as Error).message);
    }
  }

  /**
   * 對應 Android 手機版本較佳
   */
  async scan() {
    try {
      const result = await this.liff.scanCode!();
      return result;
    } catch (err) {
      console.log((err as Error).message);
      alert('您的手機版本不支援此功能，請手動輸入序號');
      return;
    }
  }

  /**
   * 對應 iOS 手機版本較佳
   */
  async scanV2() {
    try {
      // console.log('scanV2 iOS');
      const result = await this.liff.scanCodeV2();
      return result;
    } catch (err) {
      console.log((err as Error).message);
      // alert(`scan: ${(err as Error).message}`);
      alert('您的手機版本不支援此功能，請手動輸入序號');
      return;
    }
  }

  /**
   * 判斷使用者是否為follow
   */
  async isFollow(): Promise<boolean> {
    return _.get(await liff.getFriendship(), 'friendFlag', false);
  }

  close(): void {
    liff.closeWindow();
  }

  private async updateLineProfile(
    userId: string,
    displayName: string,
    pictureUrl: string,
    email: string,
    phone: string
  ) {
    if (!localStorage.getItem('lineEmail') && email) {
      localStorage.setItem('lineEmail', email);
      await this.firestore.doc(`/lineUsers/${userId}`).set(
        {
          displayName: displayName || '',
          pictureUrl: pictureUrl || '',
          email: email || '',
        },
        {
          merge: true,
        }
      );
    }
    if (!localStorage.getItem('linePhone') && phone) {
      localStorage.setItem('linePhone', phone);
      await this.firestore.doc(`/lineUsers/${userId}`).set(
        {
          displayName: displayName || '',
          pictureUrl: pictureUrl || '',
          phone: phone || '',
        },
        {
          merge: true,
        }
      );
    }
  }

  /**
   * LIFF 於開啟並初始化時，送出 LiffOpen Event
   * @param liffId
   * @param site
   */
  private liffOpenLog(liffId: string, site?: string) {
    if (this.profile.userId) {
      const url = new URL(location.href);
      let targetSite = '';
      try {
        targetSite = site || url.searchParams.get('site') || url.pathname.split('/')[2] || 'noSite';
      } catch (err) {
        console.error(err);
        targetSite = 'noSite';
      }
      this.log(targetSite, this.profile.userId, 'liffOpen', liffId, window.navigator.userAgent, location.href || '');
    }
  }
}
