import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginLiffRequest, LoginLineRequest, LoginPhoneRequest } from '@aiii/nn-types/functions/login';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  /**
   * 執行 LIFF 登入
   * @param body 表單內容
   * @returns Account Token
   */
  public async loginLiff(body: LoginLiffRequest): Promise<string> {
    const accountToken: string = await lastValueFrom(
      this.http.post(environment.api.login.loginLiff, body, { responseType: 'text' })
    );
    localStorage.setItem('token', accountToken);

    return accountToken;
  }

  /**
   * 執行 LINE 登入
   * @param body 表單內容
   * @returns Account Token
   */
  public async loginLine(body: LoginLineRequest): Promise<string> {
    const accountToken: string = await lastValueFrom(
      this.http.post(environment.api.login.loginLine, body, { responseType: 'text' })
    );
    localStorage.setItem('token', accountToken);

    return accountToken;
  }

  /**
   * 執行手機號碼登入
   * @param body 表單內容
   * @returns Account Token
   */
  public async loginPhone(body: LoginPhoneRequest): Promise<string> {
    const accountToken: string = await lastValueFrom(
      this.http.post(environment.api.login.loginPhone, body, { responseType: 'text' })
    );
    localStorage.setItem('token', accountToken);

    return accountToken;
  }
}
