import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

// 用來辨識是否為 ISO String 的正規表示式（包含 DATEONLY 的型別）
const isoStringRegex: RegExp = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|(\+|-)\d{2}:?\d{2}))?$/;

@Injectable()
export class JsonParserHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return httpRequest.responseType === 'json' ? this.handleJsonResponses(httpRequest, next) : next.handle(httpRequest);
  }

  private handleJsonResponses(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (
      next
        // 一律回傳字串，並在後續進行反序列化時順便處理型別轉換
        // 就算 API 回傳的不是 JSON 也不會出錯，例如 boolean 一樣以 parseJson('true') 的方式被解析（此時 key 為 ''），而 JSON.parse()會進到
        .handle(httpRequest.clone({ responseType: 'text' }))
        .pipe(
          map((event) => {
            // 請求送出時會先收到一個 event: { type: 0 } 的物件（此時型別為 Object 而非 HttpResponseBase），是因為其 type = HttpEventType.Sent = 0
            return event instanceof HttpResponse ? event.clone({ body: parseJson(event.body) }) : event;
          })
        )
    );
  }
}

/**
 * 巡覽 JSON 中的每個 Key-Value Pair，並將 Value 轉換成指定的型別
 * @param jsonString 序列化後的 JSON 字串
 * @returns 經過處理後的 JSON 物件
 */
function parseJson(jsonString: string): object | null {
  // Angular 會將後端執行 res.end() 後回傳來的空回應自動轉成 null，因此這邊模擬這個行為，否則下面執行 JSON.parse("") 時會噴錯
  if (!jsonString) {
    return null;
  }

  return JSON.parse(jsonString, (key: string, value: unknown) => {
    // 將 ISO String 轉型成 Date 物件
    if (typeof value === 'string' && isoStringRegex.test(value)) {
      return new Date(value);
    }

    return value;
  });
}
