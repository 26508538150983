import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlutterService {
  /**
   * 檢查此頁面是否為從 APP 開啟的 Webview
   */
  isInWebview(): boolean {
    return !!window.flutter_inappwebview;
  }

  /**
   * 呼叫從 APP 開啟 Webview 頁面時 Flutter 在頁面上建立的 Webview Handler
   * @param handlerName Handler 名稱
   * @param args 傳遞給 Handler 的參數，會在 Flutter 中處理這些參數後回傳結果
   * @returns 從 Flutter 處理後回傳的結果
   */
  private async callHandler<T>(handlerName: string, ...args: unknown[]): Promise<T> {
    if (!window.flutter_inappwebview) {
      throw Error('找不到 Webview Handler，請確認是否在 APP 內開啟此頁面');
    }

    return await window.flutter_inappwebview.callHandler(handlerName, ...args);
  }

  /**
   * 使用者在 LIFF 登入後將 Token 保存在 APP
   * @param token 使用者的 Account Token
   */
  async setToken(token: string): Promise<void> {
    await this.callHandler('setToken', token);
  }

  /**
   * 讓 APP 導到登入頁
   */
  async redirectToLogin(): Promise<void> {
    await this.callHandler('redirectToLogin');
  }

  /**
   * 讓 APP 導到有 Bottom Tab 的頁面
   */
  async redirectToIndex(): Promise<void> {
    await this.callHandler('redirectToIndex');
  }
}
