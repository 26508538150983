import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  /**
   * 紀錄瀏覽歷史的 URL
   */
  public historyUrls: string[] = [];
  public historyLength: number;
  public currentIndex: number = 0;
  public pathHistories: Record<number, string> = {};
  constructor(private router: Router) {
    this.historyLength = Object.keys(this.pathHistories).length;
  }

  /**
   * 返回上一頁
   */
  public goBack(): void {
    // 清除當前的路徑
    delete this.pathHistories[this.currentIndex];

    // 更新最新的 key 當作最新 index
    this.currentIndex = Number(Object.keys(this.pathHistories).slice(-1)[0]);

    // 設定要返回的 URL
    const previousUrl = this.pathHistories[this.currentIndex];

    // // 更新暫存的頁面長度，會影響到是否可以顯示返回按鈕
    this.historyLength = Object.keys(this.pathHistories).length;
    if (previousUrl) {
      // 返回上一頁
      this.router.navigateByUrl(previousUrl, { replaceUrl: true });
    }
  }
}
