import { InjectionToken, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { AppCheckInstances } from '@angular/fire/app-check';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ENV } from '../interfaces/firebase-env';

export function AngularFirestoreFactory(
  platformId: any,
  zone: NgZone,
  projectId: string,
  angularFireSchedulers: ɵAngularFireSchedulers,
  angularFireAuth: AngularFireAuth
): AngularFirestore {
  return new AngularFirestore(
    environment.aiiiFirebase,
    projectId,
    false,
    { experimentalForceLongPolling: false, experimentalAutoDetectLongPolling: true, merge: true },
    platformId,
    zone,
    angularFireSchedulers,
    null,
    null,
    angularFireAuth,
    null,
    null,
    null,
    null,
    null,
    null,
    null as unknown as AppCheckInstances // 若注入 AppCheckInstances 會噴「NullInjectorError: No provider for ɵAppCheckInstances!」錯誤，暫無解法
  );
}

export const FIREBASE_PROJECT_US = new InjectionToken<ENV>('firebase project injector for us');

export const FIREBASE_REFERENCES = {
  US_FIRESTORE: new InjectionToken<AngularFirestore>('project-us-firestore'),
};

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    { provide: FIREBASE_PROJECT_US, useValue: 'us' },
    {
      provide: FIREBASE_REFERENCES.US_FIRESTORE,
      deps: [PLATFORM_ID, NgZone, FIREBASE_PROJECT_US, ɵAngularFireSchedulers, AngularFireAuth],
      useFactory: AngularFirestoreFactory,
    },
  ],
})
export class FirebaseModule {}
