import { inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CanActivateFn, Router } from '@angular/router';
import * as _ from 'lodash';
import { FIREBASE_REFERENCES } from '../methods/firebase.module';
import { environment } from 'src/environments/environment';
import { SiteOption } from '@aiii/nn-types/firestore/sites';

export const blockedRouteGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const aiiiFirestore = inject<AngularFirestore>(FIREBASE_REFERENCES.US_FIRESTORE);

  // 系統人員可以進入任何頁面
  if (route.queryParams['aiiitest'] !== undefined) {
    return true;
  }

  const blockedRoutes = ((await aiiiFirestore.collection('sites').doc(environment.site).ref.get()).data() as SiteOption)
    .blockedRoutes;

  // 開始檢查每條規則
  for (const blockedRoute of blockedRoutes) {
    // 規則沒啟用就跳過
    if (!blockedRoute.enable) {
      continue;
    }

    // 檢查路由是否一致，不一致就跳過
    if (!new RegExp(`^${blockedRoute.route}$`, 'i').test(location.pathname)) {
      continue;
    }

    // 看符合路由時還有沒有需要只針對某個 Query String 的值做阻擋，沒有的話就是直接踢到維護頁面
    if (_.isEmpty(blockedRoute.query)) {
      return router.parseUrl('/maintenance');
    }

    // 檢查是否每個 Query String 都符合，都符合就代表確定進入了我們要封鎖的頁面
    if (
      Object.keys(blockedRoute.query)
        .map((key) => new RegExp(`^${blockedRoute.query[key]}$`, 'i').test(route.queryParams[key]))
        .every((isMatch) => isMatch)
    ) {
      return router.parseUrl('/maintenance');
    }
  }

  return true;
};
