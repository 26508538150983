import { childEnvironment } from './environment';

export const getEnvironmentBase = () => ({
  redirectPaths: {
    admin: {
      invitation: '/admin/invitation',
      manage: '/admin/manage/user',
      managePhysicians: `/admin/manage/physician`,
      qrcode: '/admin/qrcode',
    },
    login: '/login',
    noAccess: '/no-access',
    physician: {
      registration: '/physician/registration',
    },
    transfer: {
      previousOfficialAccount: '/transfer/previous-oa',
      registration: '/transfer/registration',
    },
    user: {
      afterLogin: '/user/after-login',
      createOrder: '/user/create-order',
      home: '/user/home',
      hospitalQrcode: '/user/hospital-qrcode',
      invitationQrcode: '/user/invitation-qrcode',
      medicineQrcode: '/user/medicine-qrcode',
      pending: '/user/pending',
      pointMission: '/user/point-mission',
      preference: '/user/preference',
      productDetail: '/user/product-detail/:productId',
      registration: '/user/registration',
    },
    userAgreement: '/user-agreement',
  },
  api: {
    account: {
      getLineUserId: `${childEnvironment.cloudrun.api}/accounts/roles/:role/:roleId`,
      getMyAccount: `${childEnvironment.cloudrun.api}/accounts/me`,
      linkAccount: `${childEnvironment.cloudrun.api}/accounts/link`,
      setAgreeTerms: `${childEnvironment.cloudrun.api}/accounts/agree-terms`,
    },
    bodyRecord: {
      createDailySport: `${childEnvironment.cloudrun.api}/body-records/daily-sport`,
      createOrUpdateDailyBodyRecord: `${childEnvironment.cloudrun.api}/body-records/body-record`,
      getAllBodyRecordsByUserId: `${childEnvironment.cloudrun.api}/body-records/all/users/:userId`,
      getDailyBodyRecord: `${childEnvironment.cloudrun.api}/body-records/body-record`,
      getDailyBodyRecordByUserId: `${childEnvironment.cloudrun.api}/body-records/body-record/users/:userId`,
      getInitialBodyRecord: `${childEnvironment.cloudrun.api}/body-records/initial`,
      getLatestRecordBeforeToday: `${childEnvironment.cloudrun.api}/body-records/latest-beforeDate/:date`,
      getLatestTwoBodyRecord: `${childEnvironment.cloudrun.api}/body-records/latest-two`,
      getLimit180BodyRecords: `${childEnvironment.cloudrun.api}/body-records`,
      getLimit180BodyRecordsByUserId: `${childEnvironment.cloudrun.api}/body-records/users/:userId`,
      getMonthBodyRecord: `${childEnvironment.cloudrun.api}/body-records/monthly-body-record`,
      getMonthBodyRecordByUserId: `${childEnvironment.cloudrun.api}/body-records/monthly-body-record/users/:userId`,
      getNonNullBodyRecord: `${childEnvironment.cloudrun.api}/body-records/non-null-body-record`,
      getNonNullBodyRecordByUserId: `${childEnvironment.cloudrun.api}/body-records/non-null-body-record/users/:userId`,
      getRecentFiveDaysBodyRecords: `${childEnvironment.cloudrun.api}/body-records/recent-five-days`,
      imageUpload: `${childEnvironment.cloudrun.api}/body-records/upload-image`,
      shareImageUpload: `${childEnvironment.cloudrun.api}/body-records/share-image`,
    },
    calendarNotification: {
      createCalendarNotification: `${childEnvironment.cloudrun.api}/calendar-notifications`,
      deleteCalendarNotification: `${childEnvironment.cloudrun.api}/calendar-notifications/:id/:userId`,
      getExpiredCalendarNotifications: `${childEnvironment.cloudrun.api}/calendar-notifications/expired`,
      getNextVisitCalendarNotifications: `${childEnvironment.cloudrun.api}/calendar-notifications/next-visit/:userId`,
    },
    city: {
      getAllCities: `${childEnvironment.cloudrun.api}/cities`,
    },
    district: {
      getAllDistricts: `${childEnvironment.cloudrun.api}/districts`,
    },
    emailVerification: {
      verifyRoleByEmail: `${childEnvironment.cloudrun.api}/email-verifications/:code`,
    },
    employee: {
      registerEmployee: `${childEnvironment.cloudrun.api}/employees/register`,
    },
    employeeInvitation: {
      createPhysicianInvitation: `${childEnvironment.cloudrun.api}/employee-invitations`,
      getPhysicianInvitation: `${childEnvironment.cloudrun.api}/employee-invitations/:code`,
    },
    food: {
      getFoods: `${childEnvironment.cloudrun.api}/foods`,
    },
    hospital: {
      getAllHospitals: `${childEnvironment.cloudrun.api}/hospitals`,
      getBoundHospitals: `${childEnvironment.cloudrun.api}/hospitals/bound`,
      getHospital: `${childEnvironment.cloudrun.api}/hospitals/:id`,
    },
    login: {
      loginLiff: `${childEnvironment.cloudrun.api}/login/liff`,
      loginLine: `${childEnvironment.cloudrun.api}/login/line`,
      loginPhone: `${childEnvironment.cloudrun.api}/login/phone`,
    },
    medicine: {
      getBoundMedicines: `${childEnvironment.cloudrun.api}/medicines/bound`,
      getBoundMedicinesByUserId: `${childEnvironment.cloudrun.api}/medicines/bound/users/:userId`,
      getMedicine: `${childEnvironment.cloudrun.api}/medicines/:id`,
      getMedicines: `${childEnvironment.cloudrun.api}/medicines`,
    },
    mission: {
      finishMission: `${childEnvironment.cloudrun.api}/users/finish-mission`,
      getMissions: `${childEnvironment.cloudrun.api}/users/missions`,
      getTodayMissions: `${childEnvironment.cloudrun.api}/users/today-missions`,
    },
    order: {
      checkOrder: `${childEnvironment.cloudrun.api}/orders/check`,
      createOrder: `${childEnvironment.cloudrun.api}/orders`,
    },
    personalSetting: {
      getPersonalSetting: `${childEnvironment.cloudrun.api}/personal-setting`,
      updatePersonalSetting: `${childEnvironment.cloudrun.api}/personal-setting`,
    },
    phoneVerification: {
      sendVerificationCode: `${childEnvironment.cloudrun.api}/phone-verifications/send-verification-code`,
      verifyPhone: `${childEnvironment.cloudrun.api}/phone-verifications/verify-phone`,
    },
    physician: {
      acceptPhysicianToPhysicianGroup: `${childEnvironment.cloudrun.api}/physicians/bound/accept`,
      deletePhysicianGroupBoundToPhysician: `${childEnvironment.cloudrun.api}/physicians/:physicianId/bound/hospitals/:hospitalId`,
      getPhysicianGroupToPhysicianViewModelByPhysicianId: `${childEnvironment.cloudrun.api}/physicians/:physicianId/bound/physician-groups`,
      getPhysiciansWithGroupAndHospital: `${childEnvironment.cloudrun.api}/physicians`,
      registerPhysician: `${childEnvironment.cloudrun.api}/physicians/register`,
      rejectPhysicianJoinPhysicianGroup: `${childEnvironment.cloudrun.api}/physicians/bound/reject`,
      updatePhysicianGroupBoundToPhysician: `${childEnvironment.cloudrun.api}/physicians/:physicianId/bound`,
      updatePhysicianGroupStatusToEditing: `${childEnvironment.cloudrun.api}/physicians/:physicianId/bound/physician-groups/:physicianGroupId`,
    },
    physicianGroup: {
      countPhysicians: `${childEnvironment.cloudrun.api}/physician-groups/:physicianGroupId/count/physicians`,
      disablePhysicianGroups: `${childEnvironment.cloudrun.api}/physician-groups/:physicianGroupId`,
      getBoundPhysicianGroups: `${childEnvironment.cloudrun.api}/physician-groups/bound`,
      getMainPhysicianExistenceMap: `${childEnvironment.cloudrun.api}/physician-groups/main-physician-existence-map`,
      getPhysicianGroupById: `${childEnvironment.cloudrun.api}/physician-groups/:physicianGroupId`,
      getPhysicianGroups: `${childEnvironment.cloudrun.api}/physician-groups`,
      getPhysicianGroupByUserId: `${childEnvironment.cloudrun.api}/physician-groups/users/:userId`,
      getPhysicianGroupToPhysician: `${childEnvironment.cloudrun.api}/physician-groups/physician`,
      getPhysicianGroupToUserViewModelsByPhysicianGroupId: `${childEnvironment.cloudrun.api}/physician-groups/:physicianGroupId/bound/users`,
      hasPhysicianGroupName: `${childEnvironment.cloudrun.api}/physician-groups/exist/name`,
      hasUser: `${childEnvironment.cloudrun.api}/physician-groups/:physicianGroupId/exist/user`,
      updatePhysicianGroupToUser: `${childEnvironment.cloudrun.api}/physician-groups/bound/users`,
    },
    physicianInvitation: {
      createPhysicianInvitation: `${childEnvironment.cloudrun.api}/physician-invitations`,
      getPhysicianInvitation: `${childEnvironment.cloudrun.api}/physician-invitations/:code`,
    },
    physicianJobTitle: {
      getPhysicianJobTitles: `${childEnvironment.cloudrun.api}/physician-job-titles`,
    },
    pointRecord: {
      getPointRecords: `${childEnvironment.cloudrun.api}/users/point-records`,
      receivePoint: `${childEnvironment.cloudrun.api}/users/receive-special-mission-point`,
    },
    preference: {
      setPreference: `${childEnvironment.cloudrun.api}/preferences`,
    },
    product: {
      getAllProductsForUser: `${childEnvironment.cloudrun.api}/products/for-user`,
      getProductForUser: `${childEnvironment.cloudrun.api}/products/:productId/for-user`,
    },
    sport: {
      getSports: `${childEnvironment.cloudrun.api}/sports`,
    },
    treatmentProgress: {
      getTreatmentProgress: `${childEnvironment.cloudrun.api}/treatment-progress`,
      getTreatmentProgressPermissions: `${childEnvironment.cloudrun.api}/treatment-progress/permissions`,
    },
    unplannedHospital: {
      requestUnplannedHospital: `${childEnvironment.cloudrun.api}/unplanned-hospitals`,
      getAllUnplannedHospitals: `${childEnvironment.cloudrun.api}/unplanned-hospitals`,
    },
    user: {
      acceptUserJoinPhysicianGroup: `${childEnvironment.cloudrun.api}/users/accept-user-join-physician-group`,
      enableMusic: `${childEnvironment.cloudrun.api}/users/me/music`,
      getAfterLoginStatus: `${childEnvironment.cloudrun.api}/users/after-login-status`,
      getUserByUserId: `${childEnvironment.cloudrun.api}/users/:userId`,
      getUsersAndPhysicianGroupIdByPhysicianId: `${childEnvironment.cloudrun.api}/users/users-and-physician-groups-by-physician-id`,
      registerUserByInvitationQrcode: `${childEnvironment.cloudrun.api}/users/register/invitation-qrcode`,
      registerUser: `${childEnvironment.cloudrun.api}/users/register`,
      rejectUserJoinPhysicianGroup: `${childEnvironment.cloudrun.api}/users/reject-user-join-physician-group`,
      resetRegistration: `${childEnvironment.cloudrun.api}/users/reset-registration`,
      setHideDailyMissionDialogAt: `${childEnvironment.cloudrun.api}/users/hide-daily-mission-dialog-at`,
      setPicture: `${childEnvironment.cloudrun.api}/users/me/picture`,
    },
    userInvitation: {
      createUserInvitation: `${childEnvironment.cloudrun.api}/user-invitations`,
      getUserInvitation: `${childEnvironment.cloudrun.api}/user-invitations/:code`,
    },
    userTransfer: {
      register: `${childEnvironment.cloudrun.api}/user-transfer/register`,
      transfer: `${childEnvironment.cloudrun.api}/user-transfer`,
      verify: `${childEnvironment.cloudrun.api}/user-transfer/verify`,
    },
  },
  site: 'obesityCarePsp',
});
