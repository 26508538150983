import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandler } from './shared/handlers/global-error-handler';
import { AuthHttpInterceptor } from './shared/interceptors/auth-http-interceptor';
import './shared/extensions/window';
import { authGuard } from './shared/guards/auth.guard';
import { JsonParserHttpInterceptor } from './shared/interceptors/json-parser-http-interceptor';
import localeZh from '@angular/common/locales/zh';
import { registerLocaleData } from '@angular/common';
import { ENABLE_PERSISTENCE, SETTINGS } from '@angular/fire/compat/firestore';
import { blockedRouteGuard } from './shared/guards/blocked-route.guard';
import { CoreModule } from './shared/methods/core.module';

// 導入中文語言環境
registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AngularFireModule.initializeApp(environment.firebase),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('./liff/liff.module').then((m) => m.LiffModule),
          canActivate: [authGuard, blockedRouteGuard],
        },
        {
          path: 'email-verification',
          loadChildren: () =>
            import('./email-verification/email-verification.module').then((m) => m.EmailVerificationModule),
          canActivate: [blockedRouteGuard],
        },
        {
          path: 'login',
          loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
          canActivate: [blockedRouteGuard],
        },
        {
          path: 'calendar',
          loadChildren: () => import('./add-to-calendar/add-to-calendar.module').then((m) => m.AddToCalendarModule),
          canActivate: [blockedRouteGuard],
        },
        {
          path: 'agreement',
          loadChildren: () => import('./agreement/agreement.module').then((m) => m.AgreementModule),
          canActivate: [blockedRouteGuard],
        },
        {
          path: 'no-access',
          loadChildren: () => import('./no-access/no-access.module').then((m) => m.NoAccessModule),
        },
        {
          path: 'maintenance',
          loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
        },
        {
          path: '**',
          loadChildren: () => import('./liff/liff.module').then((m) => m.LiffModule),
          canActivate: [authGuard, blockedRouteGuard],
        },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    CoreModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonParserHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: LOCALE_ID, useValue: 'zh-Hant' },
    {
      provide: SETTINGS,
      useValue: {
        // 如果在覆寫 SETTINGS 時沒有指定 merge: true 的話雖然會在 F12 中出現「You are overriding the original host. If you did not intend to override your settings, use {merge: true}.」警告，但可以避免以下問題
        // 1. 在查詢 Firestore 時低機率發生「FirebaseError: [code=unavailable]: Failed to get document because the client is offline.」錯誤
        // 2. 在取得整包 Collection 資料時高機率發生「WebChannelConnection RPC 'Listen' stream 0x21f1770e transport errored」錯誤
        experimentalForceLongPolling: false,
        experimentalAutoDetectLongPolling: true,
      },
    },
    {
      provide: ENABLE_PERSISTENCE,
      useValue: false,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
