import { Injectable } from '@angular/core';
import { liff } from '@line/liff';

@Injectable({
  providedIn: 'root',
})
export class TransferLiffService {
  /**
   * 舊 LIFF 的 Line UID
   */
  public lineUserId: string = '';

  /**
   * 初始化 LIFF 並取得 Line UID
   * @param liffId 舊的 LIFF ID
   */
  public async initLiff(liffId: string): Promise<void> {
    await liff.init({ liffId });
    this.lineUserId = (await liff.getProfile()).userId;
  }
}
