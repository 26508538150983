import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})

/**
 * 共用元件：confirm 彈窗
 * content：要顯示的文字內容
 * nextActionContent：執行動作的文字內容
 * cancelActionContent：取消動作的的文字內容
 * note：要顯示的備註文字內容，文字大小會比 content 文字小，顏色為灰色，可不輸入
 */
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { content: string; nextActionContent: string; cancelActionContent: string; note?: string }
  ) {
    // 設定預設樣式
    dialogRef.disableClose = true;
    dialogRef.addPanelClass('confirm-dialog-container');
  }

  /**
   * 執行下一步
   */
  nextAction(): void {
    this.dialogRef.close(true);
  }

  /**
   * 關閉畫面
   */
  close(): void {
    this.dialogRef.close(false);
  }
}
