import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { AlertDialogComponent } from '../dialogs/alert/alert-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * 共用 Alert 彈窗
   * @param content 要顯示的訊息內容
   * @param actionContent 按鈕的文字內容
   */
  async alert(content: string, actionContent?: string): Promise<void> {
    await this.open<void>(AlertDialogComponent, { content, actionContent });
  }

  /**
   * 共用 Confirm 彈窗
   * @param data
   * @returns
   */
  async confirm(data?: {
    content: string;
    nextActionContent?: string;
    cancelActionContent?: string;
    note?: string;
  }): Promise<boolean> {
    return await this.open<boolean>(ConfirmDialogComponent, data);
  }

  /**
   * 開啟 dialog
   * @param dialogComponent 想要監控的 dialog 元件
   * @param data 想要傳入 dialog 的資料
   * @param config 設定 dialog 樣式
   * @param T dialog 元件
   * @param Result dialog 回傳型別
   * @param Data dialog 的 data 傳入型別
   * @returns
   */
  async open<Result, Data = any, T = any>(
    dialogComponent: ComponentType<T>,
    data?: Data,
    config?: MatDialogConfig
  ): Promise<Result> {
    const dialog = this.dialog.open<T, Data, Result>(dialogComponent, { data, ...config });
    return await dialog.afterClosedAsync();
  }
}
