import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorHandler, inject } from '@angular/core';
import { AccountService } from '../services/account.service';
import { LiffService } from 'src/app/liff/liff.service';
import { Role } from '@aiii/nn-types/product/account';
import { TransferLiffService } from 'src/app/liff/transfer/transfer-liff.service';

/**
 * 進入各個主要頁面前的身份驗證守衛
 * @param route
 * @param state
 */
export const authGuard: CanActivateFn = async (route, state) => {
  const accountService = inject(AccountService);
  const router = inject(Router);
  const liff = inject(LiffService);
  const transferLiff = inject(TransferLiffService);
  const errorHandler = inject(ErrorHandler);

  try {
    const decodedLiffState = decodeURIComponent(new URLSearchParams(location.search).get('liff.state') || '');

    // 從舊 OA 開啟既有用戶轉移頁面，使用舊的 LIFF ID 執行 initLiff 並直接進入轉移頁面
    if (
      decodedLiffState.startsWith(environment.redirectPaths.transfer.previousOfficialAccount) ||
      state.url.startsWith(environment.redirectPaths.transfer.previousOfficialAccount)
    ) {
      await transferLiff.initLiff(environment.liff.previousLiffId);
      return true;
    }

    // 因 iOS 從 LINE 外面開啟 LIFF 連結時不會自動回到 LINE 開啟 LIFF Browser，而是開啟外部瀏覽器導致取不到 LINE User ID，所以阻擋在外部瀏覽器瀏覽 LIFF 連結的行為（已確認過不需顯示 LINE 手動登入頁）
    if (!liff.isInClient() && new URLSearchParams(location.search).has('liff.state')) {
      alert('請關閉此頁面，並使用「LINE 內建掃描器」完成驗證');
      return router.parseUrl(environment.redirectPaths.noAccess);
    }
    // 只有在 LIFF Browser 中才當成 LIFF 來執行，其餘都當成一般網頁
    else if (liff.isInClient() || new URLSearchParams(location.search).has('liff.state')) {
      await liff.initLiff(environment.liff.liffId);
    }

    // 確保使用者已經登入
    const account = await accountService.getMyAccount();

    // 同意條款頁面不做阻擋
    if (state.url.startsWith(environment.redirectPaths.userAgreement)) {
      return true;
    }

    // 如果同意條款尚未勾選同意，就回到同意條款頁面
    if (!account.agreeTerms) {
      return router.parseUrl(`${environment.redirectPaths.userAgreement}?redirectUrl=${encodeURIComponent(state.url)}`);
    }

    // 如果角色是病患就做登入後的檢查，確保該病患有通過驗證（但略過 after-login 本身與病患註冊系列的頁面）
    // TODO: 把此判斷做在 user.routes.ts 的路由守衛裡面，就不需要列這麼多白名單
    if (
      account.role === Role.User &&
      !state.url.startsWith(environment.redirectPaths.user.afterLogin) &&
      !state.url.startsWith(environment.redirectPaths.user.registration) &&
      !state.url.startsWith(environment.redirectPaths.user.hospitalQrcode) &&
      !state.url.startsWith(environment.redirectPaths.user.invitationQrcode) &&
      !state.url.startsWith(environment.redirectPaths.user.medicineQrcode)
    ) {
      return router.parseUrl(
        `${environment.redirectPaths.user.afterLogin}?redirectUrl=${encodeURIComponent(state.url)}`
      );
    }

    return true;
  } catch (err) {
    // 注意：在 Guard 裡面執行 return false 或 throw Error() 都會回到根目錄，此轉址優先於 Error Handler，因此這邊需要先觸發 Error Handler 以防抓不到網址資訊
    errorHandler.handleError(err);
    return false;
  }
};
