<p>
  {{ data.content || '' }}
  <span class="note" *ngIf="data?.note"><br />{{ data.note || '' }}</span>
</p>

<div class="button-box">
  <button mat-flat-button (click)="close()" class="cancel-button">{{ data.cancelActionContent || '取消' }}</button>
  <button mat-flat-button color="primary" class="download-button" (click)="nextAction()">
    {{ data.nextActionContent || '確認' }}
  </button>
</div>
