import * as _ from 'lodash';
import { getEnvironmentBase } from './environment-base';

export const childEnvironment = {
  isProduction: false,
  firebase: {
    apiKey: 'AIzaSyD6cchlu8BP37ycABAu3WkAFvPsVq7G2bw',
    authDomain: 'nn-platform-stage.firebaseapp.com',
    projectId: 'nn-platform-stage',
    storageBucket: 'nn-platform-stage',
    messagingSenderId: '824830670374',
    appId: '1:824830670374:web:c81637da81fb29c812d8e7',
  },
  aiiiFirebase: {
    apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
    authDomain: 'aiii-developer.firebaseapp.com',
    databaseURL: 'https://aiii-developer.firebaseio.com',
    projectId: 'aiii-developer',
    storageBucket: 'aiii-developer.appspot.com',
    messagingSenderId: '700749202278',
  },
  liff: {
    liffId: '2001571544-BbpleeMj',
    previousLiffId: '2004607345-RYZxgjjm',
    clientId: '2001571544',
    officialAccountUrl: 'https://line.me/R/ti/p/@663dshuj',
  },
  cloudrun: {
    api: 'https://nn-api-main-stage-zfnas7pnkq-de.a.run.app',
  },
};

export const environment = _.extend(childEnvironment, getEnvironmentBase());
