import { lastValueFrom } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

declare module '@angular/material/dialog' {
  interface MatDialogRef<T> {
    afterClosedAsync(): Promise<any>;
  }
}

MatDialogRef.prototype.afterClosedAsync = async function () {
  return lastValueFrom(this.afterClosed());
};
