import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm/confirm-dialog.component';

/**
 * 共用元件：alert 彈窗
 * content：要顯示的文字內容
 * actionContent：按鈕的文字內容
 */
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss',
})
export class AlertDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { content: string; actionContent: string }
  ) {
    // 設定預設樣式
    dialogRef.disableClose = true;
    dialogRef.addPanelClass('confirm-dialog-container');
  }

  /**
   * 關閉畫面
   */
  close(): void {
    this.dialogRef.close();
  }
}
