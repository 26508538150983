import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NoopError } from '../types/noop-error';
import { Router } from '@angular/router';
import { FlutterService } from '../services/flutter.service';
import { DialogService } from '../services/dialog.service';

/**
 * 全域的錯誤處理器
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private router: Router, private flutterService: FlutterService, private dialogService: DialogService) {}

  /**
   * 錯誤處理方法
   * @param error 錯誤內容
   */
  public handleError(error: any): void {
    // zone.js 會自動將原本的 error 物件包在 rejection 屬性裡面後回傳一個新的 error 物件，因此要特別提取出來
    // 但目前測試 API 回傳回來的錯誤不會出現這個情境，需要再釐清哪些情況下會有這個行為
    if (error.rejection) {
      console.warn('此 Error 包含 rejection', error);
      error = error.rejection;
    }

    // 不跳任何 Alert 給使用者看
    if (error instanceof NoopError) {
      return;
    }

    console.error(error);

    // 處理 API 回傳回來的錯誤
    if (error instanceof HttpErrorResponse) {
      this.dialogService.alert((error as HttpErrorResponse).error).then(() => {
        switch ((error as HttpErrorResponse).status) {
          case 401:
            // 如果是在 APP 就回到 APP 的登入頁面
            if (this.flutterService.isInWebview()) {
              this.flutterService.redirectToLogin();
              return;
            }

            // 轉址到登入頁面
            this.router.navigateByUrl(
              `${environment.redirectPaths.login}?redirectUrl=${encodeURIComponent(
                location.pathname + location.search
              )}`
            );
            return;
          case 403:
            // 如果是在 APP 就回到 APP 的登入頁面
            if (this.flutterService.isInWebview()) {
              this.flutterService.redirectToLogin();
              return;
            }

            // 轉址到無權限頁面
            this.router.navigateByUrl(environment.redirectPaths.noAccess);
            return;
        }
      });

      return;
    }

    // 顯示錯誤訊息給使用者
    switch (true) {
      // iOS 在省電模式下進入到有使用 Firestore 的頁面時會偶發性的噴出此錯誤，已嘗試透過 ENABLE_PERSISTENCE 處理並列為待觀察，但為了保險還是修改警告文案
      case /Failed to get document because the client is offline./.test(error.message):
        this.dialogService.alert('您目前的網路連線不穩定，請確認您的網路狀況，關閉頁面後再重新開啟頁面。');
        break;
      // 因頁面快取住了某一包舊的 Chunk 才會出現此錯誤
      case /^Loading chunk \d+ failed/.test(error.message):
        this.dialogService.alert('載入頁面失敗，請清除快取後再重新開啟頁面。').then(() => {
          location.reload();
        });
        break;
      default:
        this.dialogService.alert(error.message);
        break;
    }
  }
}
